.accordion {
  margin: 0;
  border: 1px solid #dfdfdf;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.accordion:nth-of-type(1) {
  border-top-width: 1px;
}

.accordion-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;

  color: #000000;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-title:hover {
  cursor: pointer;
}

.accordion-content {
  overflow: hidden;
  height: 0;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 130%;
  color: #3e3e3e;
  transition: all 400ms ease;

  &.open {
    padding-top: 5px;
    height: 56px;
  }
}

.btn-mesh {
  background: #0066c3;

  border-radius: 3px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  padding-top: 11px;
  padding-bottom: 11px;
  /* identical to box height */

  text-align: center;

  /* #FFFFFF */

  color: #ffffff;

  &:hover {
    color: white;
    background-color: darken(#0066c3, 4%);
  }
}

.root {
  opacity: 0;
  animation: fadeIn 700ms forwards;
  padding-top: 70px;
  .title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #444150;
  }
  .subTitle {
    margin-top: 20px;
    margin-bottom: 20px;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #444150;
  }

  .text {
    margin-top: 40px;
    margin-bottom: 15px;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */

    text-align: center;

    color: #444150;
  }

  .btnWrapper {
    display: flex;
    justify-content: center;
  }
  .button {
    width: 177px;
    max-width: 50%;
    height: 47px;
  }
}

a.dark {
  color: #444150;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

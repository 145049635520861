.verifyingPage {
  h4 {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 43px;
    /* identical to box height */
    text-align: center;
    color: #444150;
  }
  .loader {
    text-align: center;
    padding-top: 138px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .success {
      background-color: rgb(37, 189, 121);
      border-radius: 50%;
      width: 180px;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(0);
      animation: bumpIn 800ms forwards;
      svg {
        fill: white;
        color: white;
        width: 65%;
      }
    }
  }
}

.MuiCircularProgress-colorPrimary {
  color: rgb(230, 230, 230) !important;
}

@keyframes bumpIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  60% {
    transform: scale(1.1);
  }
  70% {
    transform: scale(0.9);
  }
  80% {
    transform: scale(1.1);
  }
  90% {
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

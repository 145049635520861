@import './bootstrap/index.scss';

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900');
@import url('https://fonts.googleapis.com/css2?family=Lobster');

body {
  font-family: Source Sans Pro;
  font-style: normal;
  background: #fff;
}

a {
  text-decoration: none;
  color: #6fb9ff;
  font-weight: 600;
  transition: all ease 200ms;
  &:hover {
    opacity: 0.9;
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #55555563;
}

.loader-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh;
}

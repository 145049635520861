.bodyBg {
  background-color: #f7f7f7;
}

.container {
  max-width: 440px;
  min-height: 100vh;
  margin: auto;
  padding-top: 5px;
  padding-bottom: 15px;

  display: flex;
  flex-direction: column;

  .logo {
    display: flex;
    justify-content: center;
    img {
      height: 45px;
    }
  }
  .inner {
    background-color: white;

    background-color: white;
    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04);
    margin-top: 10px;
    border-radius: 6px;
    flex-grow: 1;
  }
}

.form-text-info {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  margin-top: -5px;
  a {
    color: rgb(51, 112, 142);
    font-weight: 600;
  }
}

.yourEmail {
  display: flex;
  color: #696773;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 30px;
  align-items: center;
  .email {
    color: #418bce;
    margin-left: 5px;
  }
}

.container {
  background: #f5f8f9;
  padding: 25px;
  .title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;
    color: #000000;
  }
  .data {
    margin-top: 20px;

    .item {
      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
      display: flex;
      div:first-child {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        width: 124px;
        flex-shrink: 0;
      }
      div:last-child {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
    }
  }
}

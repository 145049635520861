div.root {
  .btnWrapper {
    display: flex;
    justify-content: center;
  }
  .button {
    margin-top: 30px;
    max-width: 80%;
    width: 224px;
    height: 47px;
  }

  .text {
    margin-top: 20px;
  }

  .dataTitle {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 140%;
    /* or 21px */

    color: #000000;
  }
}

.labelTooltipIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;

  background-color: #0264be;

  border-radius: 50%;
  align-self: center;
  margin-left: 5px;
  position: relative;
  cursor: pointer;
  svg {
    height: 8px;
    color: white;
  }
}

.inputTextLight {
  font-weight: 400;
}

.rightPlaceholder {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #6c757d;
}

.invalidFeedback {
  font-size: 0.7rem;
}

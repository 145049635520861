@import '../../scss/media-mixins.scss';

.header {
  margin-left: -40px;
  margin-right: -40px;
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 15px;
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #dfe2e6;
  align-items: flex-end;

  color: #444150;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 21px */

  @include for-phone-only {
    margin-left: -15px;
    margin-right: -15px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

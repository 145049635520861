.form-label {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #7c7a85;
}
.form-control,
.form-control.is-invalid,
.form-select,
.form-select.is-invalid {
  &:focus {
    //   background-color: rgb(255, 255, 255);
    //   border-color: rgb(134, 183, 254);
    //   outline: 0;
    //   box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    box-shadow: none;
  }
}

.form-control {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: 600;
}

.form-text {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;

  color: #444150;
}

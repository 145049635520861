.footer {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #444150;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  display: flex;
  .text {
    margin-right: 10px;
  }
  a {
    margin-top: -1px;
  }
}

@import './bootstrap.scss';
@import '../media-mixins.scss';

.app-container {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 60px;
  padding-top: 30px;
  @include for-phone-only {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.popover {
  max-width: 235px;
  border: 1px solid rgb(185, 209, 220);
  background-color: rgb(248, 248, 249);
  .popover-body {
    padding: 7px 11px;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;

    color: #444150;
  }
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before {
  border-right-color: rgb(185, 209, 220);
}

.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #f8f8f9;
}

@import './form.scss';
@import './button.scss';

.cursor-disabled {
  cursor: not-allowed;
}

.root {
  opacity: 0;
  animation: fadeIn 700ms forwards;
  .title {
    font-family: Lobster;
    font-style: normal;
    font-weight: normal;
    font-size: 39.6667px;
    line-height: 50px;
    text-align: center;
    color: #444150;
    margin-top: -160px;
  }
  .gif {
    text-align: center;
    img {
      width: 368px;
      height: 368px;
      max-width: 100%;
    }
  }
  .text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    width: 320px;
    margin: 21px auto;
  }
  .button {
    width: 224px;
    height: 47px;
    margin-top: 70px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
